<div [ngClass]="stickyClass">
  <div class="header" [ngClass]="bgClass">
    <div class="container py-3 px-0">
      <div class="d-flex justify-content-between">
        <div class="d-flex align-items-center">
          <app-spinner *ngIf="isLogoLoading && header.dynamicLogo && !logo2"></app-spinner>
          <i
            *ngIf="addHamburgerMenu && !!leftNavigate"
            class="col-auto bi bi-list cursor-pointer me-2 ham-menu"
            (click)="toggleDrawer.emit('left')"
          ></i>
          <a *ngIf="!!logo2 && header.dynamicLogo" [routerLink]="'/home'">
            <img class="img-fluid img--logo1" [src]="logo2" />
          </a>
          <a
            *ngIf="
              !isLogoLoading &&
              (!header.dynamicLogo || !logo2) &&
              !!header.firstLogo
            "
            [routerLink]="'/home'"
          >
            <img
              *ngIf="!logo2"
              class="img-fluid"
              [ngStyle]="logoStyle"
              src="assets/img/org/logo-header.svg"
            />
          </a>
          <a *ngIf="!!header.secondLogo" [routerLink]="'/home'">
            <img class="img-fluid img--logo2" [src]="header.secondLogo" />
          </a>
        </div>
        <div class="box--icons">
          <img
            *ngIf="header.pointDetail"
            class="cursor-pointer"
            src="assets/img/icon_points.png"
            width="37px"
            height="36px"
            (click)="toggleDrawer.emit('right')"
          />
          <app-notif *ngIf="header.notification" class="col-auto"></app-notif>
          <div
            class="back-to-app"
            *ngIf="showAppBackButton"
            (click)="backTopApp()"
          >
            <img src="assets/img/org/back-to-app.svg" alt="back-to-app" />
          </div>
          <app-language-change
            *ngIf="languageAvailable.length > 1 && !isLoginTnc"
            class="col-auto lang mx-2"
            [mode]="LanguageMode.iconDropdown"
            withPadding="1"
            [showNotif]="true"
          ></app-language-change>
          <app-cart-header
            *ngIf="modules.cart"
            [cartCount]="cartCount"
            [cartDetails]="cartDetails"
            [cartItems]="cartItems"
            [cartStatus]="cartStatus"
            class="col-auto"
          ></app-cart-header>
          <app-profile
            *ngIf="!isLoginTnc"
            class="col-auto profile-menu"
          ></app-profile>
        </div>
      </div>
    </div>
  </div>
  <app-breadcrumb
    *ngIf="!!segments.length"
    [segments]="segments"
  ></app-breadcrumb>
</div>
